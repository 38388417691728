/**
 *
 * Gestion des polaroids
 * Polaroid et ScrollMagic
 *
 */

import polaroid from '../misc/polaroid';

import $ from "jquery";

let arrayPolaroid = new Array();

$(document).ready(function(){

  $('[data-trigger="polaroid"]').each(function() {
    let polaroidItem = new polaroid($(this));
    polaroidItem.displayPolaroid();
    arrayPolaroid.push(polaroidItem);
  });


  $(window).resize(function(){
    arrayPolaroid.forEach(function(item, index) {
      item.displayPolaroid();
    });
  });
});
