'use strict';

class Polaroid {
    constructor($wrapper) {
        try {

            this.isDisplay = false;
            this.$wrapper = $wrapper;
            this.img = this.$wrapper.data('img');
            this.position = this.$wrapper.data('position');
            this.rotate = this.$wrapper.data('rotate');
            this.isMobile = this.$wrapper.data('mobile');
            this.isDesktop = this.$wrapper.data('desktop');

            if(this.position.length != this.rotate.length) {
                throw "missing parameters on polaroid definition data-position - data-rotate ";
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    // display Polaroid if necessary
    displayPolaroid() {

        if(!this.isDisplay) {
            let item = this;
            let zIndex = 1;

            item.position.forEach(function(element, index) {
                $('<div class="polaroid__faker" data-role="polaroid-item"></div>').appendTo(item.$wrapper).css({
                    'background-image':  `url("  ${item.img}  ")`,
                    'z-index': `-${zIndex}`,
                    transform: 'rotate('+ item.rotate[index] +'deg) translateX('+ element +')'
                });

                zIndex++;
            });
            this.isDisplay = true;
        }

        if(breakpoints.isMobile() && this.isMobile == false) {
            this.removePolaroid();
            this.isDisplay = false;
        }

        if(breakpoints.isDesktop() && this.isDesktop == false) {
            this.removePolaroid();
            this.isDisplay = false;
        }
    }

    removePolaroid() {
        let item = this;
        item.$wrapper.find('[data-role="polaroid-item"]').each(function(){
            $(this).remove();
        });
    }
}

export default Polaroid;

